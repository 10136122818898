import 'survey-react/modern.min.css';
import { HistorienPage } from './HistorienPage';
import React from 'react';

//import { StylesManager } from 'survey-react';

export const FrontPage = () => {
  return (
    <div className="container">
      <HistorienPage />
    </div>
  );
};
